<template lang="html">
  <div class="slideshow" v-if="this.numPicLoaded >= 3">
    <transition-group name="slide" tag="div" class="slide-list">
      <div
        class="slide"
        v-for="(pic,index) in pictureList"
        v-show="index === selectedIndex"
        :style="pic | absolute | cssImage"
        :key="`slide-${index}`"
      />
    </transition-group>
    <slot class="slide-content"/>
    <div class="slide-pager" v-show="pager">
      <div
        v-for="(pic, index) in pictureList"
        :class="['puce', { 'active': index==selectedIndex }]"
        :key="index"
        @click="select(index)"
      />
    </div>
  </div>
</template>

<script>
/* Attention v-if sur le slideshow indispensable sinon transition enter ne fonctionne pas */
import debounce from 'lodash.debounce'

const mod = function (value, modulo) {
  return (value % modulo + modulo) % modulo
}

export default {
  name: 'Slideshow',
  filters: {
    absolute (filename) {
      return `https://www.ldekor.fr/assets/images/home/${filename}`
    }
  },
  props: {
    auto: { type: Boolean, default: true },
    pager: { type: Boolean, default: true },
  },
  data () {
    return {
      selectedIndex: 0,
      previousIndex: 0,
      numPicLoaded: 0,
      pictureList: ['work_in_progress.jpg', 'concept.jpg', 'matieres.jpg', 'couleurs.jpg'],
      picturePath: 'https://www.ldekor.fr/assets/images/home/'
    }
  },
  computed: {
    nextIndex () {
      return this.mod((this.selectedIndex + 1), this.numPicLoaded)
    }
  },
  mounted () {
    this.slideLastTime = 0
    this.slideEllapsedTime = 0
    this.loadPic(0)
  },
  methods: {
    loadPic (index) {
      const url = this.$options.filters.absolute(this.pictureList[index])
      const img = new Image()
      img.addEventListener('load', this.onPictureComplete)
      img.src = url
    },
    onPictureComplete () {
      this.numPicLoaded++
      if (this.numPicLoaded === 3) this.intro()
      if (this.numPicLoaded < this.pictureList.length) this.loadPic(this.numPicLoaded)
    },
    prev () {
      this.previousIndex = this.selectedIndex
      this.selectedIndex = mod(this.selectedIndex - 1, this.numPicLoaded)
    },
    next () {
      this.slideEllapsedTime = window.performance.now() - this.slideLastTime
      if (this.auto && this.slideEllapsedTime > 4000) {
        this.slideLastTime = window.performance.now()
        this.slideEllapsedTime = 0
        this.previousIndex = this.selectedIndex
        this.selectedIndex = mod(this.selectedIndex + 1, this.numPicLoaded)
      }
      this.requestAnimationFrameID = requestAnimationFrame(() => this.next())
    },
    select: debounce(function(index) {
      this.slideLastTime = window.performance.now()
      this.slideEllapsedTime = 0
      this.previousIndex = this.selectedIndex
      this.selectedIndex = index
    }, 1000, true),
    intro () {
      this.slideLastTime = window.performance.now()
      this.slideEllapsedTime = 0
      this.next()
    },
  },
  watch: {
    auto (newValue) {
      if (newValue === true) this.slideLastTime = window.performance.now()
    }
  }
}
</script>

<style lang="stylus" scoped>
$slideshow-transition-duration = 1600ms
.on-mosaic .slideshow .slide-list
  opacity 0

.slideshow
  width 100%
  height 100%
  overflow hidden

.slide-list
  position fixed
  width 100vw
  height 100%
  z-index 0
  transition opacity 1s

.slide
  @extend $common-center-cover
  display block
  width 100%
  height 100%
  position absolute
  z-index 1
  top 0
  left 0

.slide-content
  position relative
  z-index 1

.slide-pager
  position fixed
  z-index 2
  bottom 20px
  width 100vw
  text-align center

  .puce
    background-color white
    display inline-block
    width 10px
    height @width
    border-radius @width * 0.5
    margin 0 10px
    transition background-color 0.5s
    cursor pointer

    &.active
      background-color $color-high

.slide-enter
  transform translate3d(100%, 0, 0)
  z-index 2

.slide-enter-active
  transition transform ($slideshow-transition-duration * 0.6) ease-in
  z-index 2

.slide-leave
  z-index 0

.slide-leave-active
  transform translate3d(-99%, 0, 0)
  transition transform $slideshow-transition-duration ease-in
  z-index 0
</style>
