<template>
  <div class="hello">
    <div class="card">
      <header>
        <h1 class="logo">L&nbsp;Dékor</h1>
        <h2 class="baseline">L'élégance de vos murs</h2>
      </header>
      <div class="news" v-if="pushNews && pushNews.enable !='false'">
        <img :src="pushNews.pic" />
        <div class="news-text">{{ pushNews.text }}</div>
      </div>
    </div>
    <div class="mouse">
      <div class="mousewheel" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    'push-news': { type: Object, default: () => {} }
  },
}
</script>

<style lang="stylus" scoped>
.on-top .hello
  .card
    opacity 1
    transition opacity 0.5s 0s

  .mouse
    display block

    @media screen and (min-width: 961px) and (max-height: 610px)
      display none

.hello.loading
  transition transform 0s 0s, opacity 0s 0s
  opacity 0
  transform scale(1.2)

  .card, .contact
    transition opacity 0s 0s
    opacity 0

  .card
    transform scale(1.2)
    transition transform 0s 0s, opacity 0s 0s

.hello
  @extend $common-center-cover
  position fixed
  display flex
  flex-direction column
  justify-content space-around
  width 100vw
  height 100%
  overflow hidden
  transform-origin 50%
  transform scale(1)
  transition transform 0.5s, opacity 1s
  z-index 0

  .card
    margin auto
    color white
    position relative
    opacity 0
    z-index 5
    transform scale(1)
    transition transform 0.5s 0.2s, opacity 1s 0.45s

    header
      padding 55px 0
      +small()
        padding 90em 120em

  .logo
    font-size 65px
    font-size 17vw
    margin 0
    padding 0
    font-family 'Dry_Brush'
    line-height 1.2
    font-weight 100
    text-align center
    +desktop()
      font-size 10vw

  .baseline
    margin 0
    padding 0
    text-align center
    font-weight $medium-weight
    font-size 20px
    font-size 5vw
    padding 0.25em
    letter-spacing 0.1em
    +desktop()
      font-size 2.25vw

  .news
    display inline-block
    box-sizing border-box
    width 100%
    background-color $color-light-grey
    padding 10px

    img
      width 40%

    .news-text
      display inline-block
      width 50%
      height 100%
      margin-left 10px
      color $color-text
      font-size 16em
      vertical-align top

      +small()
        font-size 20em

.mouse
  display none
  position absolute
  bottom 65px
  left 50%
  width 28px
  height 40px
  border-radius @width * 0.5
  border 3px solid white
  box-sizing border-box
  margin-left - @border-radius
  z-index 3

  +small()
    bottom 60px
    border none

  .mousewheel
    position absolute
    top 8px
    left 50%
    width 4px
    height @width
    border-radius @width * 0.5
    margin-left - @border-radius
    background-color white
    animation mouseanimation 3s ease-in-out-cubic 0s infinite

    +small()
      animation scrollanimation 3s ease-in-out-cubic 0s infinite

  &::after
    @extend .mouse .mousewheel
    content ''
    height 10px

    +small()
      height 30px

    background-color alpha(white, 0.4)
    animation none

@keyframes mouseanimation
  0%, 40%
    transform translateY(0)

  20%
    transform translateY(6px)

@keyframes scrollanimation
  0%, 40%
    transform translateY(0)

  20%
    transform translateY(28px)
</style>
