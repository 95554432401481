import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=754cf2ae&scoped=true"
import script from "./Materials.vue?vue&type=script&lang=js"
export * from "./Materials.vue?vue&type=script&lang=js"
import style0 from "./Materials.vue?vue&type=style&index=0&id=754cf2ae&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754cf2ae",
  null
  
)

export default component.exports