<template>
  <div class="stages">
    <div class="stages__list">
      <img
        v-for="index in 4"
        class="stages__pic"
        :src="`${index}.jpg` | stagePicPath"
        width="320"
        height="400"
        :key="`stage-pic-${index}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stages',

  filters: {
    stagePicPath (filename) {
      return `https://www.ldekor.fr/assets/images/stages/${filename}`
    }
  },
}
</script>

<style lang="stylus">
.stages {
  font-size: 16px;
}

.stages__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: @padding-top;
  gap: @padding-top;
}

.stages__pic {
  width: calc(100% - 80px);
  height: auto;

  +desktop() {
    width: ((800px - 60px) / 2);
  }
}
</style>